
import PageTitle from '@/components/PageTitleComponent.vue'
import ServicesArea from '@/components/ServicesAreaComponent.vue'
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ServicesView',
  components: {
    PageTitle,
    ServicesArea
  },
  data() {
    return {
      data: {
        services: [
          {
            img: '/img/website-development.jpeg',
            title: 'Website Development',
            description: 'Website development to fit your desired needs, from Single Page Applications to fully fledged web solutions to achieve your personal workflow.',
            icon: {
              name: 'laptop-code',
              size: '5x'
            }
          },
          {
            img: '/img/application-development.jpeg',
            title: 'Application Development',
            description: 'Need an application to reach more customers? Have the next big app idea? We can build it for you, using the latest technologies available, we can bring your mobile application to life!',
            icon: {
              name: 'mobile-alt',
              size: '5x'
            }
          },
          {
            img: '/img/graphic-design.jpeg',
            title: 'Graphic Design',
            description: 'Designs to fit your specifications, we pride ourselves on our ability to design a website or application before building it, we can even simply design your solution and let you bring it to life.',
            icon: {
              name: 'paint-brush',
              size: '5x'
            }
          }
        ]
      }
    }
  }
})
