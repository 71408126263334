
import {defineComponent} from "vue";

export default defineComponent({
  name: 'CopyrightComponent',
  data() {
    return {
      data: {
        portfolioUrl: process.env.VUE_APP_PORTFOLIO_URL
      }
    }
  }
})
