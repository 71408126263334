
import ServiceComponent from '@/components/ServiceComponent.vue'
import {BCol, BContainer, BRow} from "bootstrap-vue-next";

export default {
  name: 'ServicesAreaComponent',
  props: {
    services: Array
  },
  components: {
    BContainer,
    BRow,
    BCol,
    ServiceComponent
  }
}
