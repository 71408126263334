
import ProjectComponent from '@/components/ProjectComponent.vue'
import {defineComponent} from "vue";
import {BCol, BContainer, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: 'ProjectsAreaComponent',
  props: {
    projects: Array
  },
  components: {
    BContainer,
    BRow,
    BCol,
    ProjectComponent
  }
})
