
import {defineComponent} from "vue";
import {BNavbarNav} from "bootstrap-vue-next";
import NavigationItemComponent from "@/components/navigation/NavigationItemComponent.vue";

export default defineComponent({
  name: 'NavigationBar',
  components: {
    BNavbarNav,
    NavigationItemComponent
  },
  props: {
    classes: [Array, String]
  },
  data() {
    return {
      data: {
        items: [
          {
            to: '/',
            label: 'HOME'
          },
          {
            to: '/about',
            label: 'ABOUT US'
          },
          {
            to: '/services',
            label: 'SERVICES'
          },
          {
            to: '/projects',
            label: 'PROJECTS'
          },
          {
            to: '/contact',
            label: 'CONTACT US'
          }
        ]
      }
    }
  }
})
