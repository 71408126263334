
import BannerComponent from '@/components/BannerComponent.vue'
import GuaranteeComponent from '@/components/GuaranteeComponent.vue'
import ProcessComponent from '@/components/ProcessComponent.vue'
import BlogComponent from '@/components/BlogComponent.vue'
import {defineComponent} from "vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    BannerComponent,
    GuaranteeComponent,
    ProcessComponent,
    BlogComponent
  }
})
