
import {defineComponent} from "vue";
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import NotFoundComponent from "@/components/error/NotFoundComponent.vue";
import {BContainer} from "bootstrap-vue-next";

export default defineComponent({
  name: 'NotFoundView',
  components: {
    BContainer,
    PageTitleComponent,
    NotFoundComponent
  },
  data() {
    return {
      data: {
      }
    }
  }
})
