import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerComponent = _resolveComponent("BannerComponent")!
  const _component_GuaranteeComponent = _resolveComponent("GuaranteeComponent")!
  const _component_ProcessComponent = _resolveComponent("ProcessComponent")!
  const _component_BlogComponent = _resolveComponent("BlogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BannerComponent),
    _createVNode(_component_GuaranteeComponent),
    _createVNode(_component_ProcessComponent),
    _createVNode(_component_BlogComponent)
  ]))
}