import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue, {BToastPlugin} from 'bootstrap-vue-next'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCode, faPhone, faLaptopCode, faMobileAlt, faPaintBrush, faMapMarkerAlt, faEnvelope, faShareAlt, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'

import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'

import metadataService from "@/services/metadata.service";

library.add(faClock, faCode, faPhone, faFacebook, faTwitter, faInstagram, faPinterest, faLaptopCode, faMobileAlt, faPaintBrush, faMapMarkerAlt, faEnvelope, faShareAlt, faTimes, faExclamationCircle)
metadataService.updateMetaTags(router)


const app = createApp(App).use(router).use(store).use(BootstrapVue).use(BToastPlugin).use(ScriptX).use(Ads.AutoAdsense, { adClient: 'ca-pub-1263240325581067' })

router.isReady().then(() => {
    app.mount('#app');
});
