
import {defineComponent} from "vue";
import NavigationComponent from "@/components/navigation/NavigationComponent.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
import CopyrightComponent from "@/components/footer/CopyrightComponent.vue";

export default defineComponent({
  name: 'App',
  components: {
    NavigationComponent,
    FooterComponent,
    CopyrightComponent
  }
})
