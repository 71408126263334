import { Router } from "vue-router";

function generateTitle(title: string) {
        return process.env.VUE_APP_TITLE + ' \u00bb ' + title;
}

function updateMetaTags(router: Router) {
    router.beforeEach((to, from, next) => {
        const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
        const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

        if(nearestWithTitle){
            document.title = nearestWithTitle.meta.title as string;
        }

        Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el?.parentNode?.removeChild(el));

        if(!nearestWithMeta) {
            return next();
        }

        const tags = nearestWithMeta.meta.metaTags as Array<{ name: string, description: string }>;

        tags.map((tagDef: { name: string, description: string }) => {
          const tag = document.createElement('meta');

          Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, (tagDef as any)[key]);
          });

          tag.setAttribute('data-vue-router-controlled', '');

          return tag;
        })

        .forEach(tag => document.head.appendChild(tag));

        next();
      });
}

export default {
    generateTitle,
    updateMetaTags
}