
import {defineComponent} from "vue";
import {BButton, BCol, BContainer, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: 'BannerComponent',
  components: {
    BContainer,
    BRow,
    BCol,
    BButton
  }
})
