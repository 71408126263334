
import PageTitleComponent from '@/components/PageTitleComponent.vue'
import TeamMemberComponent from '@/components/TeamMemberComponent.vue'

export default {
  name: 'AboutView',
  components: {
    PageTitleComponent,
    TeamMemberComponent
  },
  data() {
    return {
      data: {
        members: [
          {
            name: 'Tom Alderson',
            photo: '/img/team/tom.jpg',
            role: 'Founder & CEO',
            about: 'I\'m a Software Developer with over a decade of experience, who enjoys learning new programming languages or frameworks. I founded DevItUp to help businesses out there who cannot achieve their software solution targets themselves, I regularly enjoy going for walks in my spare time if the weather permits but code is where my heart lies, so that takes up my spare time too.'
          }
        ]
      }
    }
  }
}
