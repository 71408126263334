import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "about" }
const _hoisted_2 = { class: "team-members" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!
  const _component_TeamMemberComponent = _resolveComponent("TeamMemberComponent")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitleComponent, { title: "About Us" }, {
      default: _withCtx(() => [
        _createTextVNode("We want you to understand who we are, where we came from and what we believe makes DevItUp stand out amongst the crowd of digital agencies out there. Allow us to introduce ourselves...")
      ]),
      _: 1
    }),
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data.members, (member) => {
            return (_openBlock(), _createBlock(_component_TeamMemberComponent, {
              name: member.name,
              role: member.role,
              photo: member.photo,
              about: member.about,
              data: member.role,
              key: member.name
            }, null, 8, ["name", "role", "photo", "about", "data"]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}