
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ProjectComponent',
  props: {
    title: String,
    content: String,
    img: String
  },
  methods: {
    getBackgroundImage() {
      return 'background-image: url(\'' + this.img + '\')';
    }
  }
})
