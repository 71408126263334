
import PageTitle from '@/components/PageTitleComponent.vue'
import ProjectsArea from '@/components/ProjectsAreaComponent.vue'
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ProjectsView',
  components: {
    PageTitle,
    ProjectsArea
  },
  data() {
    return {
      data: {
        projects: [
          {
            title: 'ParkPal',
            img: '/img/projects/parkpal.png',
            description: 'ParkPal is a mobile application, available on iOS and Android, supporting over 50 theme parks, that allows you to view wait times and set notifications to alert you when an attraction has reached a set amount, all of these features are at the tip of your fingers. Check out the ParkPal website <a href="https://www.parkpal.co.uk" target="_blank">here</a>.'
          },
          {
            title: 'env',
            img: '/img/projects/env.png',
            description: 'env is a visionOS application that allows you to immerse yourself in over 100+ different environments with env, take time to pause and explore new surroundings, stand on top of mountains or visit far off places. Check out the env app website <a href="https://envapp.co.uk" target="_blank">here</a>.'
          },
        ]
      }
    }
  }
})
