import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__title" }
const _hoisted_2 = { class: "title title--aqua" }
const _hoisted_3 = { class: "header__paragraph" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCol = _resolveComponent("BCol")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_BCol, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
                ]),
                _createElementVNode("p", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default")
                ])
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}