
import {defineComponent} from "vue";
import {BCol, BContainer, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: 'HeaderComponent',
  props: {
    title: String
  },
  components: {
    BContainer,
    BRow,
    BCol
  }
})
