
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { toast } from 'vue3-toastify';

import FormError from '@/components/FormErrorComponent.vue'
import {defineComponent, Ref} from "vue";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRow,
  BFormSelect,
  BFormTextarea
} from "bootstrap-vue-next";
import axios from "axios";

export default defineComponent({
  name: 'ContactForm',
  props: {
  },
  components: {
    FormError,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormRow,
    BFormInput,
    BFormSelect,
    BFormTextarea
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        subject: {
          selected: 'Free Quote',
          options: [
            { value: 'Free Quote', text: 'Free Quote' },
            { value: 'Project Enquiry', text: 'Project Enquiry' },
            { value: 'Technical Support', text: 'Technical Support' },
            { value: 'Complaint', text: 'Complaint' }
          ]
        },
        message: ''
      }
    }
  },
  validations() {
    return {
      data: {
        firstName: {
          required,
          minLength: minLength(3)
        },
        email: {
          required,
          email
        },
        subject: {
          selected: {
            required
          }
        },
        message: {
          required,
          minLength: minLength(20)
        }
      }
    }
  },
  methods: {
    resetForm() {
      this.v$.$reset();
      this.v$.data.firstName.$model = '';
      this.data.lastName = '';
      this.v$.data.email.$model = '';
      this.v$.data.subject.selected.$model = this.data.subject.options[0].value;
      this.v$.data.message.$model = '';
      this.v$.$reset();
    },
    onSubmit() {
      const firstName = this.v$.data.firstName.$model;
      const lastName = this.data.lastName;
      const email = this.v$.data.email.$model;
      const subject = this.v$.data.subject.selected.$model;
      const message = this.v$.data.message.$model;
      const position = "top-right";

      if(!this.v$.data.$anyDirty) {
        toast.error('You need to enter something into the form in order to send it.',
        {
          position
        });

        return;
      }else {

        if (this.v$.data.$errors.length) {

          toast.error('The form has errors that need fixing before you can submit it.',
          {
            position
          });

          return
        }
      }

      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.post('https://formsubmit.co/ajax/852c05f7efc0754c1a9efdf3aa5646b3', {
          _template: 'table',
          firstName,
          lastName,
          email,
          subject,
          message
      })
          .then(() => {
            this.resetForm();
            toast.success('Your message has been submitted.',
            {
              position
            });
          })
          .catch(() => {
            toast.error('There was a problem submitting your message.',
              {
                position
              });
          });
    }
  }
})
