import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "project" }
const _hoisted_2 = { class: "project__title" }
const _hoisted_3 = { class: "title title--aqua title--center" }
const _hoisted_4 = { class: "project__content" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "project__image",
      style: _normalizeStyle(_ctx.getBackgroundImage())
    }, null, 4),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", { innerHTML: _ctx.content }, null, 8, _hoisted_5)
    ])
  ]))
}