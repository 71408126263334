
import {defineComponent} from "vue";
import {BContainer, BCol, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: "ProcessComponent",
  components: {
    BContainer,
    BRow,
    BCol
  }
})
