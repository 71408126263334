
import {defineComponent} from "vue";
import NavigationBarComponent from '@/components/navigation/NavigationBarComponent.vue';
import {BCollapse, BContainer, BNavbar, BNavbarBrand, BNavbarToggle} from "bootstrap-vue-next";

export default defineComponent({
  name: 'NavigationComponent',
  props: {
    msg: String
  },
  components: {
    BNavbar,
    BContainer,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    NavigationBarComponent
  }
})
