import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!
  const _component_BoxComponent = _resolveComponent("BoxComponent")!
  const _component_BCol = _resolveComponent("BCol")!
  const _component_ContactFormComponent = _resolveComponent("ContactFormComponent")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitleComponent, { title: "Get In Touch" }, {
      default: _withCtx(() => [
        _createTextVNode("We like to hear from you, whether it be to simply check in on how we are doing or if you would like a free quote on a project you have in mind, we promise to reply as soon as possible.")
      ]),
      _: 1
    }),
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_BCol, {
              lg: "4",
              order: "2",
              "order-lg": "1",
              class: "box-wrapper"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.boxes, (box) => {
                  return (_openBlock(), _createBlock(_component_BoxComponent, {
                    content: box.content,
                    icon: { name: box.icon.name, size: box.icon.size },
                    outline: box.outline,
                    dashed: box.dashed,
                    dark: box.dashed,
                    condensed: box.condensed,
                    data: box,
                    key: box.id
                  }, null, 8, ["content", "icon", "outline", "dashed", "dark", "condensed", "data"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_BCol, {
              lg: "8",
              order: "1",
              "order-lg": "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContactFormComponent)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}