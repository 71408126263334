import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "services" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ServicesArea = _resolveComponent("ServicesArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, { title: "Services" }, {
      default: _withCtx(() => [
        _createTextVNode("We offer a variety of digital services to all types of organisations, from graphical design to complete software solutions. Browse through our available range of design and development skills and find what service best fits your needs. ")
      ]),
      _: 1
    }),
    _createVNode(_component_ServicesArea, {
      services: _ctx.data.services
    }, null, 8, ["services"])
  ]))
}