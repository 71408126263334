
import NavigationBarComponent from '../navigation/NavigationBarComponent.vue'
import {defineComponent} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {BCol, BContainer, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: 'FooterComponent',
  components: {
    FontAwesomeIcon,
    NavigationBarComponent,
    BContainer,
    BCol,
    BRow
  }
})
