import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projects" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ProjectsArea = _resolveComponent("ProjectsArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, { title: "Projects" }, {
      default: _withCtx(() => [
        _createTextVNode("Take a look at our range of past and currently ongoing projects, whether it be a simple website, an application or a business wide software solution, we will help you bring your idea into the real world.")
      ]),
      _: 1
    }),
    _createVNode(_component_ProjectsArea, {
      projects: _ctx.data.projects
    }, null, 8, ["projects"])
  ]))
}