import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "projects-available" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectComponent = _resolveComponent("ProjectComponent")!
  const _component_BCol = _resolveComponent("BCol")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BRow, { class: "justify-content-center" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
              return (_openBlock(), _createBlock(_component_BCol, {
                cols: "12",
                md: "6",
                lg: "4",
                data: project.title,
                key: project.title
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProjectComponent, {
                    img: project.img,
                    title: project.title,
                    content: project.description
                  }, null, 8, ["img", "title", "content"])
                ]),
                _: 2
              }, 1032, ["data"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}