
import {defineComponent} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {BFormInvalidFeedback} from "bootstrap-vue-next";

export default defineComponent({
  name: 'FormErrorComponent',
  components: {BFormInvalidFeedback, FontAwesomeIcon},
  props: {
    id: String
  }
})
