
import {defineComponent} from "vue";
import {BCol, BRow} from "bootstrap-vue-next";

export default defineComponent({
  name: 'team-member',
  components: {
    BCol,
    BRow
  },
  props: {
    photo: String,
    name: String,
    role: String,
    about: String
  }
})
