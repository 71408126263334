import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_BFormInvalidFeedback = _resolveComponent("BFormInvalidFeedback")!

  return (_openBlock(), _createBlock(_component_BFormInvalidFeedback, { id: _ctx.id }, {
    default: _withCtx(() => [
      _createVNode(_component_FontAwesomeIcon, {
        icon: "exclamation-circle",
        "fixed-width": ""
      }),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["id"]))
}