import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import metaDataService from '../services/metadata.service'
import HomeView from '../views/HomeView.vue'
import AboutView from "@/views/AboutView.vue";
import ServicesView from "@/views/ServicesView.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import ContactView from "@/views/ContactView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        title: metaDataService.generateTitle('Home'),
        metaTags: [
          {
            name: 'description',
            content: 'DevItUp is a leading software solutions company dedicated to empowering businesses with innovative technology. We specialize in custom software development, providing tailored solutions to meet your unique requirements. From application development and integration to software optimization, we help streamline your operations and drive business growth. With our expertise in software architecture and engineering, we deliver cutting-edge solutions that enable you to stay ahead in a rapidly evolving digital landscape. Partner with DevItUp and unlock your business potential with our transformative software solutions.'
          },
          {
            property: 'og:description',
            content: 'DevItUp is a leading software solutions company dedicated to empowering businesses with innovative technology. We specialize in custom software development, providing tailored solutions to meet your unique requirements. From application development and integration to software optimization, we help streamline your operations and drive business growth. With our expertise in software architecture and engineering, we deliver cutting-edge solutions that enable you to stay ahead in a rapidly evolving digital landscape. Partner with DevItUp and unlock your business potential with our transformative software solutions.'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView,
      meta: {
        title: metaDataService.generateTitle('About Us'),
        metaTags: [
          {
            name: 'description',
            content: 'At DevItUp, we are passionate about delivering exceptional software solutions that empower businesses to thrive in a digital world. Our dedicated team of experts brings extensive industry knowledge and technical expertise to every project we undertake. With a focus on innovation and quality, we collaborate closely with our clients to understand their unique needs and goals. As a trusted partner, we leverage our deep understanding of software development and emerging technologies to create customized solutions that drive efficiency, productivity, and growth. Discover how DevItUp can be your strategic technology partner and help you achieve your business objectives.'
          },
          {
            property: 'og:description',
            content: 'At DevItUp, we are passionate about delivering exceptional software solutions that empower businesses to thrive in a digital world. Our dedicated team of experts brings extensive industry knowledge and technical expertise to every project we undertake. With a focus on innovation and quality, we collaborate closely with our clients to understand their unique needs and goals. As a trusted partner, we leverage our deep understanding of software development and emerging technologies to create customized solutions that drive efficiency, productivity, and growth. Discover how DevItUp can be your strategic technology partner and help you achieve your business objectives.'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
    {
      path: '/services',
      name: 'services',
      component: ServicesView,
      meta: {
        title: metaDataService.generateTitle('Services'),
        metaTags: [
          {
            name: 'description',
            content: 'At DevItUp, we offer a comprehensive range of software services designed to address your specific business needs. Our services encompass custom software development, application integration, software optimization, and technology consulting. With a deep understanding of various industries, our experienced team leverages cutting-edge technologies to deliver scalable and innovative solutions. Whether you require a new software application, seamless integration of existing systems, or optimization of your software infrastructure, we have the expertise to help you achieve your goals. Partner with DevItUp for reliable and tailored software services that drive efficiency and enhance your business operations.'
          },
          {
            property: 'og:description',
            content: 'At DevItUp, we offer a comprehensive range of software services designed to address your specific business needs. Our services encompass custom software development, application integration, software optimization, and technology consulting. With a deep understanding of various industries, our experienced team leverages cutting-edge technologies to deliver scalable and innovative solutions. Whether you require a new software application, seamless integration of existing systems, or optimization of your software infrastructure, we have the expertise to help you achieve your goals. Partner with DevItUp for reliable and tailored software services that drive efficiency and enhance your business operations.'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectsView,
      meta: {
        title: metaDataService.generateTitle('Projects'),
        metaTags: [
          {
            name: 'description',
            content: 'At DevItUp, we take pride in our portfolio of successful software projects that demonstrate our expertise and commitment to delivering exceptional solutions. Our projects span various industries and showcase our capabilities in custom software development, application integration, and software optimization. From small-scale applications to enterprise-level solutions, each project is executed with meticulous attention to detail and a focus on meeting client objectives. Explore our diverse range of projects to gain insights into our technical prowess, innovation, and the value we bring to businesses. Partner with DevItUp for your next software project and experience excellence in execution and results.'
          },
          {
            property: 'og:description',
            content: 'At DevItUp, we take pride in our portfolio of successful software projects that demonstrate our expertise and commitment to delivering exceptional solutions. Our projects span various industries and showcase our capabilities in custom software development, application integration, and software optimization. From small-scale applications to enterprise-level solutions, each project is executed with meticulous attention to detail and a focus on meeting client objectives. Explore our diverse range of projects to gain insights into our technical prowess, innovation, and the value we bring to businesses. Partner with DevItUp for your next software project and experience excellence in execution and results.'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactView,
      meta: {
        title: metaDataService.generateTitle('Contact Us'),
        metaTags: [
          {
            name: 'description',
            content: 'We would love to hear from you! Get in touch with the DevItUp team through our contact page. Whether you have a question, need assistance, or want to discuss a potential collaboration, our friendly experts are here to help. At DevItUp, we prioritize effective communication and building lasting partnerships. Fill out the contact form or reach out to us directly via phone or email, and we\'ll promptly respond to your inquiry. Experience personalized attention, professional service, and discover how DevItUp can contribute to your business success. Contact us today!We would love to hear from you! Get in touch with the DevItUp team through our contact page. Whether you have a question, need assistance, or want to discuss a potential collaboration, our friendly experts are here to help. At DevItUp, we prioritize effective communication and building lasting partnerships. Fill out the contact form or reach out to us directly via phone or email, and we\'ll promptly respond to your inquiry. Experience personalized attention, professional service, and discover how DevItUp can contribute to your business success. Contact us today!'
          },
          {
            property: 'og:description',
            content: 'We would love to hear from you! Get in touch with the DevItUp team through our contact page. Whether you have a question, need assistance, or want to discuss a potential collaboration, our friendly experts are here to help. At DevItUp, we prioritize effective communication and building lasting partnerships. Fill out the contact form or reach out to us directly via phone or email, and we\'ll promptly respond to your inquiry. Experience personalized attention, professional service, and discover how DevItUp can contribute to your business success. Contact us today!'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
    {
      path: '/404',
      name: '404',
      component: NotFoundView,
      meta: {
        title: metaDataService.generateTitle('Page Not Found'),
        metaTags: [
          {
            name: 'description',
            content: 'The page you are looking for cannot be found.'
          },
          {
            property: 'og:description',
            content: 'The page you are looking for cannot be found.'
          },
          {
            property: 'keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          },
          {
            property: 'og:keywords',
            content: 'Software solutions,Custom software development,Application development,Software integration,Software optimization,Software architecture,Business software,Technology consulting,Software products,Software services,Software company,Agile development,Scalable solutions,Innovative software,Software engineering,Workflow optimization,Data-driven solutions,Digital transformation,Technology solutions,Software consulting'
          }
        ]
      }
    },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
