import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "team-member" }
const _hoisted_2 = { class: "team-member__photo" }
const _hoisted_3 = ["src", "alt", "title"]
const _hoisted_4 = { class: "team-member__profile" }
const _hoisted_5 = { class: "team-member__details" }
const _hoisted_6 = { class: "title title--aqua" }
const _hoisted_7 = { class: "team-member__role" }
const _hoisted_8 = { class: "team-member__about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCol = _resolveComponent("BCol")!
  const _component_BRow = _resolveComponent("BRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_BCol, {
          cols: "12",
          lg: "6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _ctx.photo,
                alt: _ctx.name,
                title: _ctx.name
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_BCol, {
          cols: "12",
          lg: "6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", null, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.name), 1)
                ]),
                _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.role), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.about), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}