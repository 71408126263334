import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "service-card" }
const _hoisted_2 = { class: "service-card__front" }
const _hoisted_3 = ["src", "alt", "title"]
const _hoisted_4 = { class: "service-card__up" }
const _hoisted_5 = { class: "service-card__front-content" }
const _hoisted_6 = { class: "service-card__icon" }
const _hoisted_7 = {
  key: 0,
  class: "service-card__front-button"
}
const _hoisted_8 = { class: "service-card__back" }
const _hoisted_9 = {
  key: 0,
  class: "service-card__back-close"
}
const _hoisted_10 = { class: "service-card__back-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_BButton = _resolveComponent("BButton")!
  const _component_VueFlip = _resolveComponent("VueFlip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueFlip, {
      height: "100%",
      width: "100%",
      vertical: "",
      "active-hover": "",
      class: "service-flip-component",
      modelValue: _ctx.flipped,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flipped) = $event))
    }, {
      front: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.img,
            alt: _ctx.title,
            title: _ctx.title
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: _ctx.icon.name,
                  size: _ctx.icon.size,
                  "fixed-width": ""
                }, null, 8, ["icon", "size"])
              ]),
              _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
              (_ctx.isMobile())
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_BButton, {
                      variant: "secondary",
                      onClick: _ctx.manualFlip
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("FIND OUT MORE")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      back: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.isMobile())
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: "times",
                  size: "2x",
                  onClick: _ctx.manualFlip,
                  "fixed-width": ""
                }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", null, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}