import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "services-available" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiceComponent = _resolveComponent("ServiceComponent")!
  const _component_BCol = _resolveComponent("BCol")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BRow, { class: "justify-content-center" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.services, (service) => {
              return (_openBlock(), _createBlock(_component_BCol, {
                cols: "12",
                md: "6",
                lg: "4",
                data: service.title,
                key: service.title
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ServiceComponent, {
                    img: service.img,
                    title: service.title,
                    icon: service.icon
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(service.description), 1)
                    ]),
                    _: 2
                  }, 1032, ["img", "title", "icon"])
                ]),
                _: 2
              }, 1032, ["data"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}