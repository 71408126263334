
import {defineComponent} from "vue";
import {BNavItem} from "bootstrap-vue-next";

export default defineComponent({
  name: 'NavigationItem',
  props: {
    to: String
  },
  components: {
    BNavItem
  }
})
