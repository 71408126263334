
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'BoxComponent',
  props: {
    condensed: Boolean,
    dark: Boolean,
    dashed: Boolean,
    outline: Boolean,
    icon: {
      type: Object
    },
    content: String
  },
  components: {
    FontAwesomeIcon
  }
})
