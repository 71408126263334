import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box__top" }
const _hoisted_2 = { class: "top__content" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["box", { 'box--outline': _ctx.outline, 'box--dashed': _ctx.dashed, 'box--dark': _ctx.dark, 'box--condensed': _ctx.condensed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FontAwesomeIcon, {
          icon: _ctx.icon.name,
          size: _ctx.icon.size,
          "fixed-width": ""
        }, null, 8, ["icon", "size"])
      ])
    ]),
    _createElementVNode("div", {
      class: "box__content",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_3)
  ], 2))
}