
import PageTitleComponent from '@/components/PageTitleComponent.vue'
import BoxComponent from '@/components/BoxComponent.vue'
import ContactFormComponent from '@/components/ContactFormComponent.vue'
import MapLocationComponent from '@/components/MapLocationComponent.vue'
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    BoxComponent,
    PageTitleComponent,
    ContactFormComponent,
    // MapLocationComponent
  },
  data() {
    return {
        data: {
          boxes: [
            // {
            //   id: 1,
            //   outline: true,
            //   dashed: true,
            //   dark: true,
            //   condensed: true,
            //   icon: {
            //     name: 'map-marker-alt',
            //     size: '3x'
            //   },
            //   content: `<p>Find us in Whiteley, just off junction 9 via the M27, use the map below to find out exactly where we are.</p>
            //             <address>
            //               31 Grayling Crescent,<br>
            //               Curbridge,<br>
            //               Southampton<br>
            //               SO30 2BY
            //             </address>`
            // },
            {
              id: 2,
              outline: true,
              dashed: true,
              dark: true,
              condensed: true,
              icon: {
                name: 'envelope',
                size: '3x'
              },
              content: `<p>We have a range of email addresses you can use to reach different subject matters.</p>
                        <ul>
                          <li>
                            <a href="mailto:admin@devitup.co.uk">admin@devitup.co.uk</a>
                          </li>
                          <li>
                            <a href="mailto:support@devitup.co.uk">support@devitup.co.uk</a>
                          </li>
                          <li>
                            <a href="mailto:enquiries@devitup.co.uk">enquiries@devitup.co.uk</a>
                          </li>
                          <li>
                            <a href="mailto:complaints@devitup.co.uk">complaints@devitup.co.uk</a>
                          </li>
                        </ul>`
            },
            {
              id: 3,
              outline: true,
              dashed: true,
              dark: true,
              condensed: true,
              icon: {
                name: 'share-alt',
                size: '3x'
              },
              content: `<p>Keep up to date using our social media profiles, we try to keep them up to date with our ongoing projects...</p>
                        <ul class="socials-list">
                          <li class="socials-list__item socials-list__item--twitter">
                            <a href="https://www.twitter.com/DevItUpUK" target="_blank">@DevItUpUK</a>
                          </li>
                          <li class="socials-list__item socials-list__item--instagram">
                            <a href="https://www.instagram.com/devitup/" target="_blank">@DevItUp</a>
                          </li>
                          <li class="socials-list__item socials-list__item--pinterest">
                            <a href="https://www.pinterest.co.uk/devitup/" target="_blank">@DevItUp</a>
                          </li>
                        </ul>`
            }
          ]
        }
      }
  }
})
