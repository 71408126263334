
import {VueFlip} from 'vue-flip';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {BButton} from "bootstrap-vue-next";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ServiceComponent',
  props: {
    img: String,
    title: String,
    icon: Object
  },
  components: {
    FontAwesomeIcon,
    VueFlip,
    BButton
  },
  data() {
    return {
      flipped: false
    }
  },
  methods: {
    manualFlip() {
      this.flipped = !this.flipped;
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
})
