import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "404" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!
  const _component_NotFoundComponent = _resolveComponent("NotFoundComponent")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitleComponent, { title: "Page Not Found" }, {
      default: _withCtx(() => [
        _createTextVNode("It appears you've stumbled upon a page that doesn't exist, don't worry - just use the navigation to find your way back to civilisation, no need for a map.")
      ]),
      _: 1
    }),
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_NotFoundComponent)
      ]),
      _: 1
    })
  ]))
}